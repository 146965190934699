html {
  background-color: rgb(217, 217, 217);
  transition: background-color 1s steps(5);
}

body {
  margin: 0;
  font-family: "Sligoil-Micro", Menlo, Monaco, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  pointer-events: none;
  color: #170c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: rgb(0 113 128);
  text-decoration: underline;
}

h1 {
  margin: 0;
  font-family: "IBMPlexMono-MediumItalic";
  font-size: 15px;
  word-spacing: 8px;
  letter-spacing: -0.1px;
  color: black;
  font-weight: normal;

  &::before {
    content: '"';
  }

  &::after {
    content: '"';
  }
}

h2, h2 p {
  margin: 0;
  font-family: "IBMPlexMono-LightItalic";
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  white-space: pre-wrap;
  word-spacing: 5px;
  font-weight: normal;
}

.dark {
  background-color: black;
  color: white;

  body {
    color: white;
  }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.2;
  }
  45% {
    opacity: 0.7;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mildFlicker {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.7;
  }
  66% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: "Banquise-Regular";
  src: url(./assets/fonts/Banquise-Regular.otf) format("otf"),
    url(./assets/fonts/Banquise-Regular.woff) format("woff"),
    url(./assets/fonts/Banquise-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "BluuNext-Bold";
  src: url(./assets/fonts/BluuNext-Bold.otf) format("otf"),
    url(./assets/fonts/BluuNext-Bold.woff) format("woff"),
    url(./assets/fonts/BluuNext-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Credible-Regular";
  src: url(./assets/fonts/Credible-Regular.otf) format("otf"),
    url(./assets/fonts/Credible-Regular.woff) format("woff"),
    url(./assets/fonts/Credible-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "FT88-Gothique";
  src: url(./assets/fonts/FT88-Gothique.otf) format("otf"),
    url(./assets/fonts/FT88-Gothique.ttf) format("ttf"),
    url(./assets/fonts/FT88-Gothique.woff) format("woff"),
    url(./assets/fonts/FT88-Gothique.woff2) format("woff2");
}

@font-face {
  font-family: "Garamondt-Regular";
  src: url(./assets/fonts/Garamondt-Regular.otf) format("otf"),
    url(./assets/fonts/Garamondt-Regular.ttf) format("ttf"),
    url(./assets/fonts/Garamondt-Regular.woff) format("woff"),
    url(./assets/fonts/Garamondt-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Garamondt-Italic";
  src: url(./assets/fonts/Garamondt-Italic.otf) format("otf"),
    url(./assets/fonts/Garamondt-Italic.ttf) format("ttf"),
    url(./assets/fonts/Garamondt-Italic.woff) format("woff"),
    url(./assets/fonts/Garamondt-Italic.woff2) format("woff2");
}

@font-face {
  font-family: "kaerukaeru-Regular";
  src: url(./assets/fonts/kaerukaeru-Regular.otf) format("otf"),
    url(./assets/fonts/kaerukaeru-Regular.ttf) format("ttf"),
    url(./assets/fonts/kaerukaeru-Regular.woff) format("woff"),
    url(./assets/fonts/kaerukaeru-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Louise-Regular";
  src: url(./assets/fonts/Louise-Regular.otf) format("otf"),
    url(./assets/fonts/Louise-Regular.woff) format("woff"),
    url(./assets/fonts/Louise-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Sligoil-Micro";
  src: url(./assets/fonts/Sligoil-Micro.otf) format("otf"),
    url(./assets/fonts/Sligoil-Micro.svg) format("svg"),
    url(./assets/fonts/Sligoil-Micro.ttf) format("ttf"),
    url(./assets/fonts/Sligoil-Micro.woff) format("woff"),
    url(./assets/fonts/Sligoil-Micro.woff2) format("woff2");
}

@font-face {
  font-family: "SyneMono-Regular";
  src: url(./assets/fonts/SyneMono-Regular.otf) format("otf"),
    url(./assets/fonts/SyneMono-Regular.ttf) format("ttf"),
    url(./assets/fonts/SyneMono-Regular.woff) format("woff"),
    url(./assets/fonts/SyneMono-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "terminal-grotesque_open";
  src: url(./assets/fonts/terminal-grotesque_open.otf) format("otf"),
    url(./assets/fonts/terminal-grotesque_open.woff) format("woff"),
    url(./assets/fonts/terminal-grotesque_open.woff2) format("woff2");
}

@font-face {
  font-family: "IBMPlexMono-MediumItalic";
  src: url(./assets/fonts/IBMPlexMono-MediumItalic.otf) format("otf"),
    url(./assets/fonts/IBMPlexMono-MediumItalic.ttf) format("ttf"),
    url(./assets/fonts/IBMPlexMono-MediumItalic.woff) format("woff"),
    url(./assets/fonts/IBMPlexMono-MediumItalic.woff2) format("woff2");
}

@font-face {
  font-family: "IBMPlexMono-LightItalic";
  src: url(./assets/fonts/IBMPlexMono-LightItalic.otf) format("otf"),
    url(./assets/fonts/IBMPlexMono-LightItalic.ttf) format("ttf"),
    url(./assets/fonts/IBMPlexMono-LightItalic.woff) format("woff"),
    url(./assets/fonts/IBMPlexMono-LightItalic.woff2) format("woff2");
}